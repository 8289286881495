.fc .fc-toolbar-title {
  font-size: 20px;
  font-weight: 500;
}
.fc-h-event {
  background-color: #1879c0;
  border: none;
  margin: 1px 0px;
  padding: 2px 4px;
}
.fc .fc-daygrid-day.fc-day-today {
  background-color: #ecf4ff;
}
.fc .fc-col-header-cell-cushion {
  font-size: 14px;
  font-weight: 500;
}
.fc .fc-daygrid-day-top {
  font-size: 14px;
  text-align: center;
  display: block;
}
.fc .fc-daygrid-day-frame {
  cursor: pointer;
}
.fc .fc-daygrid-day-frame:hover {
  background-color: #eee;
}
.fc .fc-daygrid-day-frame .fc .fc-col-header-cell-cushion:hover {
  background-color: #eee;
}
.fc .fc-daygrid-more-link {
  font-weight: 500;
}
.fc .fc-daygrid-more-link:hover {
  font-weight: 500;
  color: #1879c0;
}
.fc .fc-button-group > .fc-button {
  color: #464749;
  background-color: rgb(70 71 73 / 8%);
}
